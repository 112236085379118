import React from 'react';
import { NavBarLinks } from './navbar-links';
import AphexLogo from '../assets/svg/aphex-logo.svg';
import { Link } from 'gatsby';

import './navbar.scss';

/**
 * Navbar displayed at the top of all pages
 */
export const NavBar = () => {
  return (
    <div className="navbar">
      <Link to="/" aria-label="Aphex Group homepage">
        <AphexLogo />
      </Link>
      <NavBarLinks />
    </div>
  );
};
