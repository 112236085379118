import React from 'react';
import { NavLink } from './navlink';

/**
 * Container for the nav links displayed in navbar
 */
export const NavBarLinks = () => {
  return (
    <div className="navbar-links tk-sofia-pro-condensed">
      <a href="https://recruitmentbyaphex.com" target="_blank">
        RECRUITMENT
      </a>
      <a href="https://chemishield.com" target="_blank">
        CHEMISHIELD
      </a>
      <a href="https://validationbyaphex.com" target="_blank">
        VALIDATION
      </a>
      <NavLink href="/story">ABOUT US</NavLink>
      <NavLink href="/contact">CONTACT</NavLink>
    </div>
  );
};
