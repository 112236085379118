import React from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';

/**
 * Link displayed in navbar
 * @param {{ href: string, children }} props
 */
export const NavLink = props => {
  const { href, children } = props;
  const { pathname } = useLocation();
  return (
    <Link className="nav-link" data-match={href === pathname} to={href}>
      {children}
    </Link>
  );
};
