import React from 'react';
import TwitterIcon from '../assets/svg/twitter-icon.svg';
import FooterIcon from '../assets/svg/footer-logo.svg';
import LinkedInIcon from '../assets/svg/linkedin-icon.svg';
import GoogleMapsIcon from '../assets/svg/google-maps-icon.svg';

import './footer.scss';
import { ContactDetails } from './contact-detail';
import { Link } from 'gatsby';

/**
 * Footer displayed at the bottom of each page
 */
export const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-about-section">
          <div>
            <h2>About</h2>
            <p>
              Headquartered in the sunny south east of Ireland, in Co.
              Waterford, the Aphex Group was established to serve the
              recruitment needs of our clients, as well as providing a digital
              solution for waste management, all whilst addressing the
              validation needs of our clients within the Life Sciences.
            </p>
          </div>
          <div className="aphex-group-links">
            <h2>Aphex Group</h2>
            <a className="grey-link" href="https://recruitmentbyaphex.com">
              recruitmentbyaphex
            </a>
            <a className="grey-link" href="https://chemishield.com">
              chemishieldbyaphex
            </a>
            <a className="grey-link" href="https://validationbyaphex.com">
              validationbyaphex
            </a>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/cookie-policy">Cookie Policy</Link>
          </div>
          <div>
            <h2>Connect</h2>
            <ContactDetails />
          </div>
        </div>
        <div className="footer-icon-row">
          <FooterIcon />
          <div className="media-icons">
            <a
              href="https://twitter.com/aphexgroup"
              aria-label="Aphex Group Twitter"
            >
              <TwitterIcon />
            </a>
            <a
              href="https://ie.linkedin.com/showcase/aphex-group"
              aria-label="Aphex Group LinkedIn"
            >
              <LinkedInIcon />
            </a>
            <a
              href="https://g.page/aphexgroup?share"
              aria-label="Aphex Group Google Maps"
            >
              <GoogleMapsIcon />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-copyright-row">
        <p className="tk-sofia-pro-soft">&copy; 2020 Aphex Group</p>
      </div>
    </div>
  );
};
