import React from 'react';

import './privacy-policy.scss';

/**
 * Privacy Policy page document
 */
export const PrivacyPolicy = () => {
  return (
    <div id="privacy-policy">
      <div className="aphex-blue-bg">
        <h1>Privacy Policy</h1>
      </div>
      <div>
        <div>
          <hr />
          <p>(Version 1.0: Updated October 2020)</p>
          <p>
            We periodically update this Privacy Policy. We will post any privacy
            policy changes on this page and, if the changes are significant, we
            will provide a more prominent notice by sending you an email
            notification, or if you subscribe to the Platform, through the
            communications tools in the Platform.
          </p>
          <p>
            While we will notify you of any material changes to this Privacy
            Policy prior to the changes becoming effective, we encourage you to
            review this Privacy Policy periodically. We will also keep prior
            versions of this Privacy Policy in an archive for your review.
          </p>
          <p>
            If you have any questions about this Privacy Policy or our treatment
            of the information you provide us, please write to us by email at{' '}
            <a href="mailto:info@theaphexgroup.com?subject=Privacy">
              info@theaphexgroup.com
            </a>{' '}
            or by mail to Chemishield (Aphex Automations Limited), Arc Labs,
            Carriganore, Waterford, Ireland Attn: Privacy.
          </p>
        </div>
        <div className="policy-content">
          <hr />
          <h2>CONTENT SUMMARY</h2>
          <ol>
            <li>
              <a href="#info-we-collect">INFORMATION WE COLLECT</a>
            </li>
            <li>
              <a href="#how-we-use-info">HOW WE USE INFORMATION WE COLLECT</a>
            </li>
            <li>
              <a href="#how-we-share-info">
                HOW WE SHARE INFORMATION WE COLLECT
              </a>
            </li>
            <li>
              <a href="#cookie-usage">COOKIES AND SIMILAR TECHNOLOGIES</a>
            </li>
            <li>
              <a href="#personal-data-access">
                HOW TO ACCESS & CONTROL YOUR PERSONAL DATA
              </a>
            </li>
          </ol>
          <hr />
        </div>
        <ol>
          <li id="info-we-collect">
            <h2>INFORMATION WE COLLECT</h2>
            <ol type="a">
              <li>
                <h3>Use of the Website</h3>
                <p>
                  This Website is developed to facilitate people’s access to
                  information in respect of our products and services.
                  Information that we collect and manage is protected in
                  accordance with this Privacy Policy.
                </p>
                <p>
                  When you visit our Website, you are free to explore the
                  Website without providing any Personal Information about
                  yourself. When you visit the Website or register for the
                  Platform, we request that you provide Personal Information
                  about yourself, and we collect Navigational Information.
                </p>
              </li>
              <li>
                <h3>Personal Information</h3>
                <p>
                  This refers to any information that you voluntarily submit to
                  us and that identifies you personally, including contact
                  information, such as your name, e-mail address, company name,
                  address, phone number, and other information about yourself or
                  your business. Personal Information can also include
                  information about any transactions, both free and paid, that
                  you enter into on the Website, and information about you that
                  is available on the internet, such as from Facebook, LinkedIn,
                  Twitter and Google, or publicly available information that we
                  acquire from service providers.
                </p>
                <p>
                  Personal Information includes Navigational Information where
                  such information can directly or indirectly identify an
                  individual. Navigational information refers to information
                  about your computer and your visits to the Website such as
                  your IP address, geographical location, browser type, referral
                  source, length of visit and pages viewed. Except for this, we
                  do not collect Sensitive Information from you.
                </p>
              </li>
              <li>
                <h3>Sensitive Information</h3>
                <p>
                  This refers to credit or debit card numbers, personal
                  financial account information, social security numbers,
                  passport numbers, driver's license numbers or similar personal
                  identifiers, racial or ethnic origin, physical or mental
                  health condition or information, or other employment,
                  financial or health information.
                </p>
              </li>
              <li>
                <h3>Log Files</h3>
                <p>
                  When you view content provided by us, we automatically collect
                  information about your computer hardware and software. This
                  information can include your IP address, browser type, domain
                  names, internet service provider (ISP), the files viewed on
                  our site (e.g., HTML pages, graphics, etc.), operating system,
                  clickstream data, access times and referring website
                  addresses. This information is used by Aphex Group to provide
                  general statistics regarding use of the Website. For these
                  purposes, we do link this automatically collected data to
                  Personal Information such as name, email address, address, and
                  phone number.
                </p>
              </li>
              <li>
                <h3>Information About Children</h3>
                <p>
                  The Website is not intended for or targeted at children under
                  18, and we do not knowingly or intentionally collect
                  information about children under 18. If you believe that we
                  have collected information about a child under 18, please
                  contact us at{' '}
                  <a href="info@theaphexgroup.com">info@theaphexgroup.com</a>,
                  so that we may delete the information.
                </p>
              </li>
            </ol>
          </li>
          <li id="how-we-use-info">
            <h2>HOW WE USE INFORMATION WE COLLECT</h2>
            <ol type="a">
              <li>
                <h3>Compliance with Our Privacy Policy</h3>
                <p>
                  We use the information we collect only in compliance with this
                  Privacy Policy. Customers who subscribe to our Subscription
                  Services are obligated through our agreements with them to
                  comply with this Privacy Policy.
                </p>
              </li>
              <li>
                <h3>We Never Sell Personal Information</h3>
                <p>
                  We will never sell your Personal Information to any third
                  party.
                </p>
              </li>
              <li>
                <h3>Use of Personal Information</h3>
                <p>
                  In addition to the uses identified elsewhere in this Privacy
                  Policy, we may use your Personal Information to:
                </p>
                <ol type="i">
                  <li>
                    improve your browsing experience by personalizing the
                    Website
                  </li>
                  <li>
                    send information or Aphex Group content to you which we
                    think may be of interest to you by post, email, or other
                    means and send you marketing communications relating to our
                    business
                  </li>
                  <li>
                    promote use of our services to you and share promotional and
                    information content with you in accordance with your
                    communication preferences
                  </li>
                  <li>
                    provide other companies with statistical information about
                    our users -- but this information will not be used to
                    identify any individual user
                  </li>
                  <li>
                    send information to you regarding changes to our Customer
                    Terms of Service, Privacy Policy (including the Cookie
                    Policy), or other legal agreements
                  </li>
                  <li>meet legal requirements</li>
                </ol>
                <p>
                  We may, from time to time, contact you on behalf of external
                  business partners about a particular offering that may be of
                  interest to you. In those cases, we do not transfer your
                  Personal Information to the third party.
                </p>
              </li>
              <li>
                <h3>Legal basis for processing Personal Information</h3>
                <p>
                  Where necessary and applicable, Aphex Group is the data
                  controller of your personal information. Queries in respect of
                  privacy can be directed to us at{' '}
                  <a href="mailto:info@theaphexgroup.com?subject=Privacy">
                    info@theaphexgroup.com
                  </a>
                  .
                </p>
                <p>
                  Our legal basis for collecting and using the personal
                  information described above will depend on the personal
                  information concerned and the specific context in which we
                  collect it. However, we will normally collect personal
                  information from you only where we have your consent to do so,
                  where we need the personal information to perform a contract
                  with you, or where the processing is in our and your
                  legitimate interests and not overridden by your data
                  protection interests or fundamental rights and freedoms. In
                  some cases, we may also have a legal obligation to collect
                  personal information from you.
                </p>
                <p>
                  If we ask you to provide personal information to comply with a
                  legal requirement or to perform a contract with you, we will
                  make this clear at the relevant time and advise you whether
                  the provision of your personal information is mandatory or not
                  (as well as of the possible consequences if you do not provide
                  your personal information). Similarly, if we collect and use
                  your personal information in reliance on our legitimate
                  interests (or those of any third party), we will make clear to
                  you at the relevant time what those legitimate interests are.
                </p>
              </li>
              <li>
                <h3>Use of Navigational Information</h3>
                <p>
                  We use Navigational Information to operate and improve the
                  Website/Platform. We may also use Navigational Information
                  alone or in combination with Personal Information to provide
                  you with personalized information about Aphex Group.
                </p>
              </li>
              <li>
                <h3>Customer Testimonials and Comments</h3>
                <p>
                  We post customer testimonials and comments on our Website,
                  which may contain Personal Information. We obtain each
                  customer's consent via email prior to posting the customer's
                  name and testimonial where such testimonials are consented to
                  by such customers.
                </p>
              </li>
              <li>
                <h3>Security of your Personal Information</h3>
                <p>
                  We use a variety of security technologies and procedures to
                  help protect your Personal Information from unauthorized
                  access, use or disclosure. We secure the Personal Information
                  you provide on computer servers in a controlled, secure
                  environment, protected from unauthorized access, use or
                  disclosure. All Personal Information is protected using
                  appropriate physical, technical and organizational measures.
                  For more on Security at Aphex Group, please contact{' '}
                  <a href="mailto:info@theaphexgroup.com?subject=Security">
                    info@theaphexgroup.com
                  </a>{' '}
                  Attn: Security.
                </p>
              </li>
              <li>
                <h3>Social Media Features</h3>
                <p>
                  Our Website includes Social Media Features, which may include
                  Like and Share buttons, as well as connections to social media
                  platforms such as Twitter and LinkedIn. These are buttons or
                  interactive mini programs that run on our sites. These
                  features may collect your IP address, which page you are
                  visiting on our sites, and may set a cookie to enable the
                  feature to function properly. Social Media Features are either
                  hosted by a third party or hosted directly on our Website.
                  This Privacy Policy does not apply to these features. Your
                  interactions with these features are governed by the Privacy
                  Policy and other policies of the companies providing them.
                </p>
              </li>
              <li>
                <h3>External Website</h3>
                <p>
                  Our Website provides links to other Websites. We do not
                  control, and are not responsible for, the content or practices
                  of these other Website. Our provision of such links does not
                  constitute our endorsement of these other Website, their
                  content, their owners, or their practices. This Privacy Policy
                  does not apply to these other Website, which are subject to
                  any privacy and other policies they may have.
                </p>
              </li>
              <li>
                <h3>Public Forums</h3>
                <p>
                  We offer publicly accessible blogs. Please keep in mind that
                  if you directly disclose any information through our blogs or
                  contact forms, this information may be collected and used by
                  others. We will correct or delete any information you have
                  posted on the Website if you so request, as described in
                  "Opting Out and Unsubscribing" below. From time to time we may
                  moderate or prevent or block comments on our blogs. This does
                  not create an obligation on us to moderate content created by
                  third parties and entered into public sections of our
                  blog/Website from time to time. Readers and users are urged to
                  use common sense and to use discretion when they are using
                  (browsing) and posting to such Website functionality.
                </p>
              </li>
              <li>
                <h3>Retention of Personal Information</h3>
                <p>
                  How long we keep information we collect about you depends on
                  the type of information. as described in further detail below.
                  After such time, we will either delete or anonymize your
                  information or, if this is not possible, then we will securely
                  store your information and isolate it from any further use
                  until deletion is possible.
                </p>
                <p>
                  We retain Personal Information that you provide to us where we
                  have an ongoing legitimate business need to do so (for
                  example, as needed to comply with our legal obligations,
                  resolve disputes and enforce our agreements).
                </p>
                <p>
                  When we have no ongoing legitimate business need to process
                  your Personal Information, we securely delete the information
                  or anonymise it or, if this is not possible, securely store
                  your Personal Information and isolate it from any further
                  processing until deletion is possible. We will delete this
                  information at an earlier date if you so request, as described
                  in the "How to Access & Control Your Personal Data" below.
                </p>
                <p>
                  If you have elected to receive marketing communications from
                  us, we retain information about your marketing preferences for
                  a reasonable period of time from the date you last expressed
                  interest in our content, products, or services, such as when
                  you last opened an email from us or ceased using your
                  Chemishield account. We retain information derived from
                  cookies and other tracking technologies for a reasonable
                  period of time from the date such information was created. The
                  data our customers collect by using the Subscription Service
                  is retained according to the relevant agreements with our
                  customers.
                </p>
              </li>
            </ol>
          </li>
          <li id="how-we-share-info">
            <h2>HOW WE SHARE INFORMATION WE COLLECT</h2>
            <ol type="a">
              <li>
                <h3>Service Providers</h3>
                <p>
                  We employ other companies and people to provide services to
                  visitors to our Website.
                </p>
              </li>
              <li>
                <h3>Compelled Disclosure</h3>
                <p>
                  We reserve the right to use or disclose your Personal
                  Information if required by law or if we reasonably believe
                  that use or disclosure is necessary to protect our rights,
                  protect your safety or the safety of others, investigate
                  fraud, or comply with a law, court order, or legal process or
                  the Revenue Commissioners.
                </p>
              </li>
            </ol>
          </li>
          <li id="cookie-usage">
            <h2>COOKIES AND SIMILAR TECHNOLOGIES</h2>
            <ol type="a">
              <li>
                <h3>Cookies</h3>
                <p>
                  Aphex Group use cookies or similar technologies (such as web
                  beacons) to analyse trends, administer the website, track
                  users’ movements around the website, and to gather demographic
                  information about our user base as a whole.
                </p>
              </li>
            </ol>
          </li>
          <li id="personal-data-access">
            <h2>HOW TO ACCESS & CONTROL YOUR PERSONAL DATA</h2>
            <ol type="a">
              <li>
                <h3>
                  Reviewing, Correcting and Removing Your Personal Information
                </h3>
                <p>You have the following data protection rights:</p>
                <ol type="i">
                  <li>
                    You can request access, correction, updates or deletion of
                    your personal information.
                  </li>
                  <li>
                    You can object to processing of your personal information,
                    ask us to restrict processing of your personal information
                    or request portability of your personal information.
                  </li>
                  <li>
                    If we have collected and process your personal information
                    with your consent, then you can withdraw your consent at any
                    time. Withdrawing your consent will not affect the
                    lawfulness of any processing we conducted prior to your
                    withdrawal, nor will it affect processing of your personal
                    information conducted in reliance on lawful processing
                    grounds other than consent.
                  </li>
                  <li>
                    You have the right to complain to a data protection
                    authority about our collection and use of your personal
                    information. To exercise any of these rights, please contact
                    us at{' '}
                    <a href="mailto:info@theaphexgroup.com?subject=Privacy">
                      info@theaphexgroup.com
                    </a>
                    , Attn: Privacy or by mail to Aphex Group Limited, Arc Labs,
                    Carriagnore, Waterford, Ireland Attn: Privacy. We will
                    respond to your request to change, correct, or delete your
                    information within a reasonable timeframe and notify you of
                    the action we have taken.
                  </li>
                </ol>
              </li>
              <li>
                <h3>Anti-Spam Policy</h3>
                <p>
                  Our Terms and Conditions, apply to us and to our customers
                  and, among other things, they prohibit use of the Platform to
                  send unsolicited commercial email in violation of applicable
                  laws, and requires the inclusion in every email sent using the
                  Platform of an "opt-out" mechanism and other required
                  information. We require all of our customers to agree to
                  adhere to the Terms and Conditions at all times, and any
                  violations of the Terms and Conditions by a customer can
                  result in immediate suspension or termination of access to the
                  Platform.
                </p>
              </li>
              <li>
                <h3>To Unsubscribe from our Communications</h3>
                <p>
                  You may unsubscribe from our marketing communications by
                  clicking on the "unsubscribe" link located on the bottom of
                  our e-mails, updating your communication preferences, or by
                  sending us email us at{' '}
                  <a href="mailto:info@theaphexgroup.com?subject=Privacy">
                    info@theaphexgroup.com
                  </a>{' '}
                  or postal mail to Aphex Group Limited, Arc Labs, Carriagnore,
                  Waterford, Ireland Attn: Privacy. Customers cannot opt out of
                  receiving transactional emails related to their account with
                  us or the Platform.
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p className="copyright">
          Aphex Group - 2020 &copy; Copyright – all rights reserved
        </p>
      </div>
    </div>
  );
};
