import React from 'react';
import './contact-details.scss';

/**
 * Used on the contact page and the footer
 */
export const ContactDetails = () => {
  return (
    <div className="contact-details">
      <p>
        ArcLabs Innovation Centre,
        <br />
        Waterford Institute of Technology West Campus, Carriganore,
        <br />
        Co. Waterford X91 P20H
      </p>
      <hr />

      <a href="mailto:info@theaphexgroup.com" className="email-link">
        info@theaphexgroup.com
      </a>
      <a href="tel:+35351576025">+353 (51) 576 025</a>
    </div>
  );
};
