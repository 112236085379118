import React from 'react';
import { NavBar } from '../components/navbar';

import '../scss/global.scss';
import { Footer } from '../components/footer';
import { PrivacyPolicy } from '../components/privacy-policy';

export default function () {
  return (
    <>
      <NavBar />
      <PrivacyPolicy />
      <Footer />
    </>
  );
}
